'use strict';

$(function(){
    var main = $('main'),
        footer = $('footer');

    var adjust = function(){
        main.css('padding-bottom', footer.outerHeight() + 20);
    };

    $(window).on('resize', function(){
        adjust();
    });

    adjust();
});