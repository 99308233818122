'use strict';

$(function(){
	
	$("#tthSearchButton").on("click", function () {
	    var state = $(this).data('state');
	    state = !state;
	    if (state) {
	    	$("#searchFormDiv").removeClass("hide");
	        $("#searchFormDiv").addClass("show");
	    } else {
	        $("#searchFormDiv").removeClass("show");
	        $("#searchFormDiv").addClass("hide");
	    }
	    $(this).data('state', state);
	});
	
    var form = $('.search-form'),
        helper = $('.search-helper', form),
        helper_timer = null,
        HELPER_VISIBILITY_TIME = 2000;

    $('[name="no_js"]', form).remove();

    $('[name="q[dt][s][device_type]"]').on('change', function(ev){
        location.href = '/catalog?'
            + encodeURIComponent('q[dt][s][device_type]')
            + '=' + $(this).val();
    });

    $('.range-slider').each(function(){
        var slider_elm = $(this),
            slider,
            min = slider_elm.data('min'),
            max = slider_elm.data('max'),
            from = slider_elm.data('from'),
            to = slider_elm.data('to'),
            min_control = $('[name="' + slider_elm.data('min-control') + '"]'),
            max_control = $('[name="' + slider_elm.data('max-control') + '"]');

        slider_elm.ionRangeSlider({
            'type': 'double',
            'min': min,
            'max': max,
            'from': from,
            'to': to,
            'hide_from_to':true,
            'onChange': function(data){
                min_control.val(data.from);
                max_control.val(data.to);
                console.log('change');
            },
            'onFinish': function(){
                changed(min_control);
            }
        });

        slider = slider_elm.data('ionRangeSlider');

        min_control.on('input change', function(){
            slider.update({
                'from': min_control.val(),
                'to': max_control.val()
            });
        });

        max_control.on('input change', function(){
            slider.update({
                'from': min_control.val(),
                'to': max_control.val()
            });
        });
    });

    var changed = function(control){
        var control_offset = control.offset().top - form.offset().top,
            search = form.serialize();

        $.get('/catalog?' + search, function(data){
            clearTimeout(helper_timer);

            $('.product-count', helper).text(data.count);
            helper
                .stop()
                .css({
                    'top': control_offset,
                    'opacity': 1
                })
                .addClass('enabled');

            helper_timer = setTimeout(function(){
                helper.animate({
                    'opacity':0
                }, 500, function(){
                    helper.removeClass('enabled');
                });
            }, HELPER_VISIBILITY_TIME);
        });
    };

    $('input:not([type="hidden"]), select', form).on('change input', function(ev){
        var control = $(this);
        changed(control);
    });

    form.on('submit', function(ev){
        ev.preventDefault();
        var search = form.serialize(),
            product_list = $('.product-list');

        if(product_list.length){
            $.get('/catalog?'+search+'&get_data=1', function(data){
                console.log(data);
                $('.product-list').replaceWith(data['product-list']);
                $('.paginator').replaceWith(data['paginator']);
            });
        }else{
            location.href = '/catalog?'+search;
        }
    });



});