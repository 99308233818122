
$('.main-menu__btn').on('click', function () {
  $('.main-menu-items').toggleClass('mb-hidden');
});

$("#catalogButton").on("click", function () {
  var state = $(this).data('state');
  state = !state;
  if (state) {
    $(".category-tree").removeClass("mb-hidden");
    $(".category-tree").addClass("mb-visible");
  } else {
    $(".category-tree").removeClass("mb-visible");
    $(".category-tree").addClass("mb-hidden");
  }
  $(this).data('state', state);
});
