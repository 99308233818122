'use strict';

$(function(){
    $('.menu-btn').click(function() {
        $('.main-menu').show();
       /*document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;*/
    });
    $('.menu-close-btn').click(function() {
        /*const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);*/
        $('.main-menu').hide();
    });
});