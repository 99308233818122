'use strict';

$(function(){
    var container = $('.latest-news'),
        news_links = $('.news-item', container),
        news_images = $('.news-image', container);

    news_images.first().addClass('current');
    news_links.each(function(index){
        $(this).on('mouseover', function(ev){
            news_images.removeClass('current');
            news_images.eq(index).addClass('current');
        });
    });
});